<template>
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      ref="SSearch"
      v-if="SSearchShow"
    ></s-search>
    <div class="table-button table-button-flex">
      <div>
        <a-button
          v-if="isShowBtns.indexOf('OrderSent-/api/constructionPlan/add') > -1"
          type="primary"
          @click="GoToBuildSchedule(record, 'add')"
          >新增</a-button
        >
      </div>
      <div>
        <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
          >刷新</a-button
        >
        <columns-set
          tableName="OrderSent"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="cdId"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: '1400px', y: 'calc(82vh - 150px )' }"
      tableLayout="fixed"
    >
      <!--  @change="changeTable" -->
      <!-- <span slot="wsBcId" slot-scope="text">
        <div>{{ CompaniesResult(text) }}</div>
      </span> -->
      <span slot="tworksite" slot-scope="text">
        <div>{{ text.wsName }}</div>
      </span>
      <span slot="cdPId" slot-scope="text, record">
        <div>{{ record.tproject ? record.tproject.pname : "" }}</div>
      </span>

      <span slot="tproject" slot-scope="text">
        {{ text ? (text.tworkerType ? text.tworkerType.wtType : "") : "" }}
      </span>

      <span slot="cdReceive" slot-scope="text, record">
        {{ text + "/" + record.cdPeople }}
      </span>

      <span slot="cwExpectStartTime" slot-scope="text, record">
        <div v-if="text == ''">{{ "-" }}</div>
        <div v-else>
          {{ $Format(text).slice(0, 10) }}-{{
            $Format(record.cdExpectEndTime).slice(0, 10)
          }}
        </div>
      </span>
      <span slot="cdCreateTime" slot-scope="text">
        <div v-if="text == ''">{{ "-" }}</div>
        <div v-else>
          {{ $Format(text).slice(0, 10) }}
        </div>
      </span>
      <!-- 操作 -->
      <!-- text, record -->
      <span slot="action" slot-scope="text, record" class="action">
        <!-- <a class="editBtn" @click="GoToBuildSchedule(record, 'edit')">详情</a> -->
        <a-popconfirm
          v-if="
            isShowBtns.indexOf('OrderSent-/api/constructionDetail/update') > -1
          "
          title="确定删除此条派单信息吗?"
          @confirm="() => cancelSend(record)"
        >
          <a class="delName">取消</a>
        </a-popconfirm>
      </span>
    </s-table>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { SelectOptions } from "@/config/SelectOptions.js";
import { getWorkSitelist } from "@/api/apiJF/schedule";
import {
  GetConstructionDetailList,
  UpDateconstructionDetail,
} from "@/api/apiJF/order";
import { GetCompanies } from "@/api/device";

// 搜索条件
const queryItems = [
  {
    label: "项目经理",
    value: "cdWsWIdJl",
    type: "input",
  },
  {
    label: "分公司",
    value: "cdBcIds",
    type: "select",
    selectOption: "CompanyListUnAll",
  },
  {
    label: "工地名称",
    value: "cdWsName",
    type: "input",
  },
  {
    label: "施工周期",
    value: "cwExpectTime",
    type: "dateRange",
    range: ["cdExpectStartTime", "cdExpectEndTime"],
    keyType: "WorkerAttend",
    defaultValue: [],
  },
  {
    label: "施工项",
    value: "cwPId",
    type: "input",
  },
];

export default {
  name: "OrderSent",
  components: {
    STable,
    SSearch,
    ColumnsSet,
  },
  data() {
    this.queryItems = queryItems;
    // this.columns = columns;
    return {
      tableColumns: [],
      SSearchShow: true,
      // 查询参数
      queryParam: {
        cdWsWIdJl: "",
        cdBcIds: 18,
        cdWsName: "",
        cdExpectEndTime: "",
        cdExpectStartTime: "",
        cwPId: "",
        cdDispatchState: 2,
        // cwWsId: "",
        // cwPId: "",
        // cwWpId: "",
        // cwWId: "",
        // cwState: "1",

        pageNo: 1,
        pageSize: 10,
      },
      isShowBtns: [],
      record: {
        // cdBcIds: 0,
      },
      CompanyList: {},
      type: "",
      filters: {},
      CompanyListid: [],
      pageNo: 1,
      pageSize: 10,
      columns: [
        {
          title: "工地",
          // width: "100px",
          scopedSlots: { customRender: "tworksite" },
          dataIndex: "tworksite",
          ellipsis: true,
          // align: "center",
        },
        {
          title: "施工项",
          width: "150px",
          dataIndex: "cdPId",
          scopedSlots: { customRender: "cdPId" },
          // align: "center",
        },
        {
          title: "所需时间",
          width: "100px",
          scopedSlots: { customRender: "cdDuration" },
          dataIndex: "cdDuration",
          align: "center",
        },
        {
          title: "施工周期",
          width: "200px",
          scopedSlots: { customRender: "cwStartTime" },
          dataIndex: "cwStartTime",
          align: "center",
        },
        {
          title: "工人岗位",
          width: "100px",
          dataIndex: "tproject",
          scopedSlots: { customRender: "tproject" },
          align: "center",
        },

        {
          title: "所需工人数",
          width: "100px",
          dataIndex: "cdPeople",
          align: "center",
        },
        {
          title: "派单规则",
          width: "100px",
          dataIndex: "cdRuleType",
          align: "center",
        },
        {
          title: "接单情况",
          width: "100px",
          dataIndex: "cdReceive",
          scopedSlots: { customRender: "cdReceive" },
          align: "center",
        },
        {
          title: "指定工人情况",
          width: "110px",
          dataIndex: "constructionWorkers",
          customRender: (text, record) => {
            let nameList = [];
            text.forEach((el) => {
              nameList.push(el.tworker.wname);
            });
            return nameList.toString();
          },
          align: "center",
        },
        {
          title: "派单时间",
          width: "100px",
          dataIndex: "cdCreateTime",
          scopedSlots: { customRender: "cdCreateTime" },
          align: "center",
        },
        {
          title: "操作",
          width: "100px",
          scopedSlots: { customRender: "action" },
          fixed: "right",
          dataIndex: "action",
          align: "center",
        },
      ],
    };
  },
  created() {
    this.GetCompaniesId();
    this.tableColumns = this.columns;
    this.changeColumns();
    this.filters = SelectOptions;
    // console.log(CompaniesResult(24));
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtnsJF").split(",");
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          // console.log(res);
          // this.filters.CompanyList.length = 0;

          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
        })
        .then(() => {
          this.queryParam.cdBcIds = this.CompanyListid.toString();

          this.$refs.table.refresh(true);

          //当包含的分公司小于1个的时候，查询条件不显示分公司查询 表格不显示分公司名称
          if (this.CompanyListid.length < 1) {
            let data = [...this.queryItems];
            this.queryItems = data.filter((item) => item.label != "公司名称");
            if (this.queryItems.length == 0) {
              this.SSearchShow = false;
            }
            let data1 = [...this.columns];
            this.tableColumns = data1.filter(
              (item) => item.title != "公司名称"
            );
            this.columns = data1.filter((item) => item.title != "公司名称");
          }
        });
    },
    CompaniesResult(a) {
      return this.CompanyList[a];
    },
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(localStorage.getItem("OrderSentColumnsSet"));
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = this.columns.filter((el) =>
        value.includes(el.dataIndex)
      );
      this.tableColumns = selectColumns.length ? selectColumns : this.columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.queryParam.pageNo = parameter.pageNo;
        this.queryParam.pageSize = parameter.pageSize;
      } else {
      }
      let newObject = {};
      for (let el in this.queryParam) {
        if (this.queryParam[el] != "" && this.queryParam[el] != undefined) {
          newObject[el] = this.queryParam[el];
        }
      }
      let orderParam = Object.keys(newObject);
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetConstructionDetailList(params).then((res) => {
        // res.data.pageNo = res.data.page;
        // res.data.totalCount = res.data.sum;
        // console.log("排期列表", res);
        return res.data.data ? res.data : res;
      });
    },

    fetchUsers(value) {
      // this.queryParam = value;
      for (let el in this.queryParam) {
        this.queryParam[el] = value[el];
      }
      // console.log("this.queryParam", this.queryParam, value);
      if (this.queryParam.cdBcIds == "") {
        this.queryParam.cdBcIds = this.CompanyListid.toString();
      }
      this.queryParam.cdDispatchState = 2;
      this.$refs.table.refresh(true);
    },
    // 取消派单
    cancelSend(record) {
      let params = record;
      console.log("params", record);
      params.cdDispatchState = 1;
      UpDateconstructionDetail(params).then((res) => {
        // console.log("取消派单", res.code);
        if (res.code == 200) {
          this.$message.success("取消派单成功");
        } else {
          this.$message.error(res.msg);
        }
      });
      this.$refs.table.refresh(true);
    },
  },
};
</script>
<style lang="less" scoped>
</style>